import { sngApi } from "@/api";
import {
    getConnectionsMessageError,
    getMessageError
} from "@/helpers";

/**
 *  Create a commodity identification
 */
export const createCommodityIdentification = async ({ commit }, { t, data: dataForm }) => {
    try {
        commit( 'setLoading', true );

        const {
            commodity_identification,
            serie_generator_rule
        } = dataForm.value;

        const serie_generator_rule_id = serie_generator_rule.value;

        const params = {
            commodity_identification: {
                identification: commodity_identification
            }
        };

        await sngApi.post(
            `/api/series_generator_rules/${ serie_generator_rule_id }/commodity_identifications`,
            params
        );

        commit( 'setLoading', false );

        return {
            ok: true,
            message: t('commodity_identifications.api.create_ok')
        }
    } catch ( error ) {
        commit('setLoading', false);

        const message = getMessageError(
            error,
            t('commodity_identifications.api.create_error'),
            t('commodity_identifications.api.commodity_exist')
        );

        return {
            ok: false,
            message
        }
    }
}

/**
 *  Get all commodity identifications with possible transitions
 */
export const getCommodityIdentifications = async ({ commit }, { t, requestedPage = 1 }) => {
    try {
        commit('setLoading', true);

        const relations = 'series_generator_rule.fragment_codes.supported_character,' +
            'series_generator_rule.fragment_codes.fragment_code_state,series_generator_rule.generation_function,' +
            'commodity_identification_state,workflow_history,possible_transitions';

        const pageSize = 30;
        const allData = [];
        let currentPage = requestedPage || 1;

        const { data, headers } = await sngApi.get(
            `/api/commodity_identifications?relations=${relations}&page=${currentPage}`
        );

        const pageData = getCommodityIdentificationsData(data);
        if (!pageData) {
            throw new Error('no data');
        }

        const totalCount = headers['x-total-count'];
        if (!totalCount) {
            throw new Error('no total count');
        }

        allData.push(...pageData);

        while (allData.length < totalCount && pageData.length === pageSize) {
            currentPage++;
            const response = await sngApi.get(
                `/api/commodity_identifications?relations=${relations}&page=${currentPage}`
            );
            const nextPageData = getCommodityIdentificationsData(response.data);
            if (!nextPageData) break;
            allData.push(...nextPageData);
        }

        commit('setCommodityIdentifications', allData);
        commit('setLoading', false);
        return { ok: true };

    } catch (error) {
        commit('setLoading', false);
        return {
            ok: false,
            message: getConnectionsMessageError(
                error,
                t('commodity_identifications.api.get_all_error')
            )
        };
    }
};

/**
 *  Get a commodity identifications with possible transitions
 */
export const getCommodityIdentificationById = async ({ commit }, { t, commodity_identification_id }) => {
    try {
        commit('setLoading', true);

        const relations = 'series_generator_rule.fragment_codes.supported_character,' +
            'series_generator_rule.fragment_codes.fragment_code_state,series_generator_rule.generation_function,' +
            'commodity_identification_state,workflow_history,possible_transitions';

        const { data } = await sngApi.get(
            `/api/commodity_identifications/${ commodity_identification_id }?relations=${ relations }`
        );

        const finalData = getCommodityIdentificationsData( data );

        if ( !finalData ) {
            throw new Error('no data');
        }

        commit('setCommodityIdentification', finalData);
        commit('setLoading', false);

        return {
            ok: true
        };
    } catch ( error ) {
        commit('setLoading', false);

        const message = getConnectionsMessageError(
            error,
            t('commodity_identifications.api.get_error')
        );

        return {
            ok: false,
            message
        }
    }
}


/**
 *  Delete a commodity identification
 */
export const deleteCommodityIdentification = async ({ commit }, { t, commodityIdentification }) => {
    try {
        commit('setLoading', true);

        const {
            commodity_identification_id,
            serie_generator_rule_id
        } = commodityIdentification;

        await sngApi.delete(
            `/api/series_generator_rules/${ serie_generator_rule_id }/commodity_identifications/${ commodity_identification_id }`
        );

        commit('setLoading', false);

        return {
            ok: true,
            message: t('commodity_identifications.api.delete_ok')
        }
    } catch ( error ) {
        commit('setLoading', false);

        const message = getConnectionsMessageError(
            error,
            t('commodity_identifications.api.delete_error')
        );

        return {
            ok: false,
            message
        }
    }
}

/**
 *  Update a commodity identification
 */
export const updateCommodityIdentification = async ({ commit }, { t, commodityIdentification }) => {
    try {
        commit('setLoading', true);

        const {
            commodity_identification,
            commodity_identification_id,
            serie_generator_rule
        } = commodityIdentification.value;

        const serie_generator_rule_id = serie_generator_rule.value;

        const params = {
            commodity_identification: {
                identification: commodity_identification
            }
        };

        await sngApi.put(
            `/api/series_generator_rules/${ serie_generator_rule_id }/commodity_identifications/${ commodity_identification_id }`,
            params
        );

        commit('setLoading', false);

        return {
            ok: true,
            message: t('commodity_identifications.api.update_ok')
        }
    } catch ( error ) {
        commit('setLoading', false);

        const message = getMessageError(
            error,
            t('commodity_identifications.api.update_error'),
            t('commodity_identifications.api.commodity_exist')
        );

        return {
            ok: false,
            message
        }
    }
}

/**
 *  Update a commodity identification state
 */
export const updateCommodityIdentificationState = async ({ commit }, { t, commodityIdentification, stateTo }) => {
    try {
        commit('setLoading', true);

        const {
            commodity_identification_id,
            serie_generator_rule_id
        } = commodityIdentification;

        const params = {
            transition: {
                commodity_identification_state_code: stateTo
            }
        };

        await sngApi.patch(
            `/api/series_generator_rules/${ serie_generator_rule_id }/commodity_identifications/${ commodity_identification_id }`,
            params
        );

        commit('setLoading', false);

        return {
            ok: true,
            message: t('commodity_identifications.api.update_state_ok')
        }
    } catch ( error ) {
        commit('setLoading', false);

        const message = getConnectionsMessageError(
            error,
            t('commodity_identifications.api.update_error')
        );

        return {
            ok: false,
            message
        }
    }
}

/**
 * Maps the input data array to a new array of objects containing information about commodity identifications.
 *
 * @param {Array|Object} data - The input array containing commodity identification data objects
 * @returns {Array|Object} - An array of objects with mapped commodity identification data
 */
export const getCommodityIdentificationsData = (data) => {
    const mapIdentification = (item) => ({
        serie_generator_rule_id: item.series_generator_rule.id,
        generation_function_name: item.series_generator_rule.generation_function.name,
        serie_generator_rule_name: item.series_generator_rule.name,
        commodity_identification_id: item.id,
        commodity_identification: item.identification,
        commodity_identification_state: item.commodity_identification_state.name,
        cluster_size: item.series_generator_rule.cluster_size,
        generation_size: item.series_generator_rule.generation_size,
        possible_transitions: Object.values(item.possible_transitions).map(transition => ({
            id: transition.to.code,
            name: transition.to.name
        })),
        fragment_codes: item.series_generator_rule.fragment_codes.map(fragment => ({
            id: fragment.id,
            position: fragment.fragment_position,
            length: fragment.length,
            fragment_code_state: fragment.fragment_code_state.name,
            supported_characters_id: fragment.supported_character.id,
            supported_characters: fragment.supported_character.characters
        }))
    });

    if (Array.isArray(data)) {
        return data.map(mapIdentification);
    } else if ((typeof data === 'object') && (data !== null)) {
        return mapIdentification(data);
    }
    return null;
};
