import { useI18n } from 'vue-i18n';
import { nextTick, ref } from 'vue';

export default function useVFTable () {
    const { t } = useI18n();
    const basePrefix = 'table';
    const dropdownMenuContainer = ref({});
    const dropdownMenuStates = ref({});
    const dropdownMenuPosition = ref({ top: '0px', left: '0px' });

    const onSetDropdownMenuContainerRef = (el, objectId) => {
        if (el) {
            dropdownMenuContainer.value[objectId] = el;
        }
    };

    const onToggleDropdownMenu = async (id, event) => {
        await nextTick();

        Object.keys(dropdownMenuStates.value).forEach(key => {
            if (key !== id) {
                dropdownMenuStates.value[key] = false;
            }
        });

        const isCurrentlyOpen = !!dropdownMenuStates.value[id];

        if (!isCurrentlyOpen) {
            const maxAttempts = 3;
            let attempts = 0;

            while (attempts < maxAttempts) {
                const dropdownMenuRef = dropdownMenuContainer.value[id];

                if (dropdownMenuRef) {
                    dropdownMenuStates.value[id] = true;
                    try {
                        await alignDropdownMenu(event, dropdownMenuRef);
                        return;
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.warn('Error menu align:', error);
                    }
                }

                await new Promise(resolve => setTimeout(resolve, 50));
                attempts++;
            }

        } else {
            dropdownMenuStates.value[id] = false;
        }
    };

    const alignDropdownMenu = async (event, dropdownMenuRef) => {
        if (!event?.currentTarget || !dropdownMenuRef) {
            throw new Error('Error required elements not available for menu align');
        }

        await nextTick();

        const rect = event.currentTarget.getBoundingClientRect();
        const dropdownMenuHeight = dropdownMenuRef.offsetHeight;
        const dropdownMenuWidth = dropdownMenuRef.offsetWidth;

        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceRight = window.innerWidth - rect.left;

        const hasSpaceBelow = spaceBelow >= dropdownMenuHeight;
        const topPosition = hasSpaceBelow
            ? `${rect.bottom}px`
            : `${rect.top - dropdownMenuHeight}px`;

        const hasSpaceRight = spaceRight >= dropdownMenuWidth;
        const leftPosition = hasSpaceRight
            ? `${rect.left}px`
            : `${rect.right - dropdownMenuWidth}px`;

        dropdownMenuPosition.value = {
            top: topPosition,
            left: leftPosition
        };
    };

    const isDropdownMenuOpen = ( id ) => {
        return !!dropdownMenuStates.value[ id ];
    };

    const onHandleClickOutsideMenu = (event) => {
        const openMenus = Object.entries(dropdownMenuStates.value)
            .filter(([, isOpen]) => isOpen)
            .map(([key]) => key);

        if (openMenus.length === 0) return;

        openMenus.forEach(key => {
            const container = dropdownMenuContainer.value[key];
            if (container && !container.contains(event.target)) {
                dropdownMenuStates.value[key] = false;
            }
        });
    };

    const onAutomationTag = () => {
        setSearchInputAutomationTag();
        setHeaderAutomationTag();
        setEntriesPerPageSelectAutomationTag();
        setPaginatorAutomationTag();
        setNavigationButtonsAutomationTag();
    }

    const setSearchInputAutomationTag = () => {
        const inputTableSearch = document.querySelector('[id*=vgt-search]');
        if ( inputTableSearch ) {
            inputTableSearch.setAttribute('data-automation', `${ basePrefix }-search`);
        }
    }

    const setHeaderAutomationTag = () => {
        const headersTable = document.querySelectorAll('.vgt-left-align.sortable');
        headersTable.forEach( header => {
            const headerText = header.querySelector('span').textContent.trim();
            header.setAttribute('data-automation', `${ basePrefix }-header-${ headerText }`);
        });
    }

    const setEntriesPerPageSelectAutomationTag = () => {
        const entriesPerPageTableSelect = document.querySelector('[id*=vgt-select-rpp]');
        if ( entriesPerPageTableSelect ) {
            entriesPerPageTableSelect.setAttribute('data-automation', `${ basePrefix }-select-entries`);
            const paginatorOptionsTable = entriesPerPageTableSelect.querySelectorAll('option');
            if ( paginatorOptionsTable ) {
                paginatorOptionsTable.forEach( option => {
                    const optiontext = option.textContent.trim();
                    option.setAttribute('data-automation', `${ basePrefix }-select-option-${ optiontext }`);
                });
            }
        }
    }

    const setPaginatorAutomationTag = () => {
        const paginatorTable = document.querySelector('[id*=vgt-page-input]');
        if ( paginatorTable ) {
            paginatorTable.setAttribute('data-automation', `${ basePrefix }-paginator`);
        }
    }

    const setNavigationButtonsAutomationTag = () => {
        const navigationButtonsTable = document.querySelectorAll('.footer__navigation__page-btn');
        navigationButtonsTable.forEach( button => {
            const buttonText = button.querySelector('span:not([aria-hidden="true"])').textContent.trim();
            button.setAttribute('data-automation', `${ basePrefix }-navigation-${ buttonText }`);
        });
    }

    const onGetSearchOptions = ( placeholder ) => {
        return {
            enabled: true,
            placeholder
        };
    }

    const onGetPaginationOptions = () => {
        return {
            enabled: true,
            mode: 'records',
            perPage: 30,
            perPageDropdownEnabled: false,
            nextLabel: t('general.table.next_label'),
            prevLabel: t('general.table.prev_label'),
            ofLabel: t('general.table.of_label')
        }
    }

    const onPaginationChange = ( params, onFetchPageData ) => {
        const requestedPage = params.currentPage;
        onFetchPageData( requestedPage );
    }

    return {
        dropdownMenuContainer,
        dropdownMenuPosition,
        isDropdownMenuOpen,
        onAutomationTag,
        onGetPaginationOptions,
        onGetSearchOptions,
        onHandleClickOutsideMenu,
        onPaginationChange,
        onSetDropdownMenuContainerRef,
        onToggleDropdownMenu
    }
}
