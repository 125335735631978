import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default function useVFButtonAuth() {
  const router = useRouter();
  const route = useRoute();
  const urlVF2 = ref('');
  const fromToVF2 = ref('');
  const enableButtonBack = ref(false);

  const handleRedirectData = (params) => {
    fromToVF2.value = params.get("client") || '';
    const token = params.get("token");
    if (token) {
      localStorage.setItem("token", token);
    }
    urlVF2.value = params.get("url_referrer") || document.referrer || '';

    localStorage.setItem("FromToVF2", fromToVF2.value);
    localStorage.setItem("urlVF2", urlVF2.value);
  };

  const cleanupQueryParams = () => {
    setTimeout(() => {
      const restQuery = { ...route.query };
      delete restQuery.redirect_data;
      router.replace({ query: restQuery });
    }, 50);
  };

  const loadStoredData = () => {
    fromToVF2.value = localStorage.getItem("FromToVF2") || '';
    urlVF2.value = localStorage.getItem("urlVF2") || '';
  };

  watch(() => route.query.redirect_data, (redirect_data) => {
    if (redirect_data) {
      const params = new URLSearchParams(atob(redirect_data));
      handleRedirectData(params);
      cleanupQueryParams();
    } else {
      loadStoredData();
    }
    enableButtonBack.value = !!urlVF2.value;
  }, { immediate: true });

  return {
    urlVF2,
    fromToVF2,
    enableButtonBack
  };
}